import { useMemo, useState } from "react"

type P = {
	data: any[]
	pageSize?: number
}

export const usePagination = ({ pageSize = 9, data }: P) => {
	const pageCount = useMemo(() => {
		return Math.ceil(data.length / pageSize)
	}, [pageSize, data])
	const [currentPage, setCurrentPage] = useState(1)

	const pageData = useMemo(() => {
		const startPoint = (currentPage - 1) * pageSize
		return data.slice(startPoint, startPoint + pageSize)
	}, [currentPage, data, pageSize])

	return { currentPage, pageCount, setCurrentPage, pageData }
}
