import { post } from "./utils"

export type DownloadDocumentBody = {
	email?: string
	phone?: string
	wechat_id?: string
	document_type: string
	document_name: string
	organization: string | null
	job_title: string | null
	join_newsletter: boolean
	download_link: string
}
type DownloadDocumentData = {
	email: string
	document_type: string
	document_name: string
	organization: string
	job_title: string
	download_link: string
	join_newsletter: boolean
	email_verified: boolean
	download_document_id: string
}
type DownloadDocumentExtraProps = {
	verification_required: true
}

export const downloadDocument = (body: DownloadDocumentBody) => {
	return post<
		DownloadDocumentBody,
		DownloadDocumentData,
		DownloadDocumentExtraProps
	>("/_download_document", {
		body,
	})
}

export const submitPuzzle = (body: DownloadDocumentBody) => {
	return post<
		DownloadDocumentBody,
		DownloadDocumentData,
		DownloadDocumentExtraProps
	>("/_puzzle_marketing_campaign", {
		body,
	})
}

export type DocumentDownloadEmailVerificationData = {
	download_link: string
}
export type DocumentDownloadEmailVerificationBody = {
	download_document_id: string
	code: string
}
export const documentDownloadEmailVerification = (
	body: DocumentDownloadEmailVerificationBody
) => {
	return post<
		DocumentDownloadEmailVerificationBody,
		DocumentDownloadEmailVerificationData
	>("/website/_verify", {
		body,
	})
}
