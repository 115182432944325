import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Container, PrimaryBtn } from "components/library"
import { parseSearchQuery } from "utils/general"
import {
	documentDownloadEmailVerification,
	DocumentDownloadEmailVerificationBody,
} from "services"
import { Loading } from "components/library"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { SuccessIllustration } from "components/library/illustrations/success-illustration";

type Status = "loading" | "success" | "error"

const DocumentEmailVerify = () => {
	const { t } = useTranslation()
	const [status, setStatus] = useState<Status>("loading")
	const [error, setError] = useState("")
	const [successMessage, setSuccessMessage] = useState("")
	const [downloadLink, setDownloadLink] = useState("")

	async function verifyEmail(payload: DocumentDownloadEmailVerificationBody) {
		const data = await documentDownloadEmailVerification(payload)
		if (data.result.status === "Failed") {
			setStatus("error")
			setError(data.result.message)
			return
		}
		setStatus("success")
		setSuccessMessage(data.result.message)
		setDownloadLink(data.result.data.download_link)
	}

	useEffect(() => {
		const { search } = location
		const queries = parseSearchQuery<{
			download_document_id: string
			code: string
		}>(search)

		if (!queries.download_document_id || !queries.code) {
			setStatus("error")
			setError(t("download-document.invalid-url"))
		} else {
			verifyEmail({
				code: queries.code,
				download_document_id: queries.download_document_id,
			})
		}
	}, [])

	return (
		<Container className="flex py-20 items-center flex-col">
			{status === "loading" && (
				<div className="flex flex-col gap-4 items-center">
					<Loading size={40} />
					<p className="text-lg sm:text-xl">Verifying Email...</p>
				</div>
			)}
			{status === "success" && (
				<div className="flex flex-col gap-6 items-center">
					<SuccessIllustration />
					<p className="text-lg sm:text-2xl text-center">{successMessage}</p>
					<a href={downloadLink} target="_blank">
						<PrimaryBtn>{t("common.words.download")}</PrimaryBtn>
					</a>
				</div>
			)}
			{status === "error" && (
				<div className="flex flex-col gap-6 items-center">
					<p className="text-lg sm:text-2xl text-center">{error}</p>
					<Link to="/case-studies-n-white-papers">
						<PrimaryBtn tabIndex={-1}>
							{t("header.navigation-items.3.subitems.4")}
						</PrimaryBtn>
					</Link>
				</div>
			)}
		</Container>
	)
}

export default DocumentEmailVerify

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
